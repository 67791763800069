import { useState } from "react";
import { Col, Form, InputGroup } from "react-bootstrap";

export const InputField = (props: {
  name: string;
  value: string;
  setValue: (value: string) => void;
  validated: boolean;
  isValid?: (value: string) => boolean;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
}) => {
  const [changed, setChanged] = useState(false);
  const isValid = (value: string) => {
    if (!changed && !props.validated) {
      return true;
    }
    if (value === "" && !props.required) {
      return true;
    }
    if (props.isValid !== undefined) {
      return props.isValid(value);
    }
    return true;
  };
  return (
    <Col sm={12} md={6}>
      <Form.Label className={props.required ? "required" : ""}>
        {props.name}
      </Form.Label>
      <InputGroup className="mb-3">
        <Form.Control
          disabled={props.disabled}
          placeholder={props.placeholder ?? props.name}
          value={props.value}
          required={props.required}
          isInvalid={!isValid(props.value)}
          onChange={(e) => {
            if (!changed) {
              setChanged(true);
            }
            props.setValue(e.target.value);
          }}
        />
      </InputGroup>
    </Col>
  );
};
