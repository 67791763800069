import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Container, Row, Col, InputGroup, Form, Table } from "react-bootstrap";
import { SeriesSelect } from "../addScore/components/seriesSelect.components";
import { getScores } from "./scoreList.query";
import { IScore, ISeriesDetailed } from "../../utils/types/api.types";
import { formatScore } from "../../utils/formatting.util";

export const ScoreList = () => {
  const [series, setSeries] = useState(-1);
  const { data, isLoading } = useQuery({
    queryKey: ["scores", series],
    queryFn: () => getScores(series),
  });
  const seriesItems = data?.map((series) => (
    <SeriesItem key={series.id} series={series} />
  ));
  return (
    <Container className="list-score">
      <h1 className="title">Scores</h1>
      <Row>
        <Col sm={6}>
          <Form.Label>Filter op reeks</Form.Label>
          <InputGroup className="mb-3">
            <SeriesSelect
              value={series}
              setValue={setSeries}
              validated={false}
              defaultOptionEnabled={true}
              defaultOptionText={"Alle reeksen"}
            />
          </InputGroup>
        </Col>
        <Col sm={12} offset={6}>
          {seriesItems}
        </Col>
      </Row>
    </Container>
  );
};

const SeriesItem = ({ series }: { series: ISeriesDetailed }) => {
  const scoreItems = series.scores.map((score, i) => (
    <ScoreItem index={i + 1} score={score} />
  ));
  return (
    <div>
      <h2>{series.name}</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Voornaam</th>
            <th>Achternaam</th>
            <th>Gemeente</th>
            <th>Voertuig</th>
            <th>Resultaat</th>
          </tr>
        </thead>
        <tbody>{scoreItems}</tbody>
      </Table>
    </div>
  );
};
const ScoreItem = ({ score, index }: { score: IScore; index: number }) => {
  const result = formatScore(score.score, score.time);
  return (
    <tr>
      <td>{index}</td>
      <td>{score.first_name}</td>
      <td>{score.last_name}</td>
      <td>{score.city ?? "N/A"}</td>
      <td>{score.vehicle ?? "N/A"}</td>
      <td>{result}</td>
    </tr>
  );
};
