import { formatScore, rankToOrdinal } from "../../../utils/formatting.util";
import { IScoreRanked } from "../../../utils/types/api.types";

export const NewScore = (props: { score: IScoreRanked }) => {
  return (
    <div className="new-score">
      <h1>{formatScore(props.score.score, props.score.time)}</h1>
      <h2>
        {props.score.first_name} {props.score.last_name}
      </h2>
      <h2>{rankToOrdinal(props.score.rank)} plaats</h2>
    </div>
  );
};
