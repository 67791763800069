import { useEffect, useState } from "react";
import {
  IScoreRanked,
  ISeries,
  ISocketData,
} from "../../utils/types/api.types";
import { TopScoreList } from "./components/topScoreList.component";
import { NewScore } from "./components/newScore.component";
import { getSeries } from "../addScore/addScore.query";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export const ScoreBoard = () => {
  const queryClient = useQueryClient();
  const [message, setMessage] = useState<null | IScoreRanked>(null);
  const [seriesIndex, setSeriesIndex] = useState(0);

  const { data: seriesData, isLoading } = useQuery({
    queryKey: ["series"],
    queryFn: getSeries,
  });

  const onOpen = () => {
    console.log("WebSocket connection established");
  };
  const onMessage = (event: MessageEvent) => {
    const data: ISocketData<"publishMessage", IScoreRanked> = JSON.parse(
      event.data
    );
    console.log("Message from server ", data);
    setMessage(data.data);
    queryClient.invalidateQueries(["scores", data.data.series.id]);
  };
  const onClose = () => {
    console.log("WebSocket connection closed. Reconnecting...");
    setTimeout(connectWebSocket, 1000); // Reconnect after 3 seconds
  };
  const connectWebSocket = (): WebSocket => {
    const socket = new WebSocket(
      "wss://6dwqedxsv7.execute-api.eu-central-1.amazonaws.com/dev-d4fd1b2"
    );
    socket.addEventListener("open", onOpen);
    socket.addEventListener("message", onMessage);
    socket.addEventListener("close", onClose);
    return socket;
  };

  useEffect(() => {
    let socket: WebSocket = connectWebSocket();
    return () => {
      socket.removeEventListener("open", onOpen);
      socket.removeEventListener("message", onMessage);
      socket.removeEventListener("close", onClose);
      socket.close();
    };
  });

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (message) {
      timeout = setTimeout(() => {
        const newSeriesIndex = seriesData?.findIndex(
          (series) => series.id === message.series.id
        );
        setSeriesIndex(newSeriesIndex ?? 0);
        setMessage(null);
      }, 5000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [message, seriesData]);

  useEffect(() => {
    let seriesTimeout: NodeJS.Timeout | null = null;
    if (!seriesData) {
      return;
    }
    seriesTimeout = setTimeout(() => {
      queryClient.invalidateQueries(["scores", seriesData[seriesIndex].id]);
      setSeriesIndex((seriesIndex + 1) % (seriesData ?? []).length);
    }, 10000);

    return () => {
      if (seriesTimeout) clearTimeout(seriesTimeout);
    };
  }, [seriesIndex, seriesData, queryClient]);

  if (isLoading || !seriesData) return <div>Loading...</div>;
  const currentSeries: ISeries = seriesData[seriesIndex];
  const series = message ? message.series : currentSeries;
  if (!series) return <div>Series not found</div>;

  const seriesLists = seriesData.map((series, index) => (
    <TopScoreList
      key={index}
      series={series}
      hidden={series.id !== currentSeries.id}
    />
  ));
  return (
    <div className="scoreboard">
      <div className={`header ${series.color}`}>
        <h1>{series.name}</h1>
        <h2>{series.subtitle ?? ""}</h2>
      </div>
      <div className="scores">
        {message ? <NewScore score={message} /> : null}
        {seriesLists}
      </div>
    </div>
  );
};
