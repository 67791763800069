import { makeUrl } from "../../utils/makeUrl.util";
import { ISeriesDetailed } from "../../utils/types/api.types";

export const getScores = async (series: number): Promise<ISeriesDetailed[]> => {
  if (series === -1) {
    const url = makeUrl(`/api/series/all_scores`);
    const response = await fetch(url);
    return response.json();
  }

  const url = makeUrl(`/api/series/${series}`);
  const response = await fetch(url);
  const result: ISeriesDetailed = await response.json();
  return [result];
};
