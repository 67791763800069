export const extractTime = (time: string): number => {
  const regex = /^([0-5]?[0-9]):([0-5]?[0-9])$/;
  const match = time.match(regex);
  if (match === null) {
    console.log("is nan");

    return NaN;
  }
  const minutes = parseInt(match[1]);
  const seconds = parseInt(match[2]);
  return minutes * 60 + seconds;
};

export const formatScore = (
  score: number,
  time: number | null | undefined
): string => {
  console.log("time: ", time, time !== undefined);

  return `${score === 100 ? "FP" : score} ${
    time !== undefined && time !== null ? formatTime(time) : ""
  }`;
};

const formatTime = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `(${minutes}'${seconds}")`;
};

export const rankToOrdinal = (rank: number): string => {
  switch (rank) {
    case 1:
      return "1ste";
    default:
      return `${rank}de`;
  }
};
