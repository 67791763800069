import { makeUrl } from "../../utils/makeUrl.util";
import { IScore } from "../../utils/types/api.types";

export const getTopScores = async (series: number) => {
  const url = makeUrl(`/api/series/${series}/scores`);
  const params = new URLSearchParams({ limit: "10" });
  const response = await fetch(url + "/?" + params.toString());
  const result: IScore[] = await response.json();
  return result;
};
