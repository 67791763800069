import { formatScore } from "../../../utils/formatting.util";

export const ScoreRow = (props: {
  position: number;
  firstName: string;
  lastName: string;
  score: number;
  time?: number;
}) => {
  return (
    <tr className={props.time !== undefined ? "full-pull" : ""}>
      <td>{props.position}</td>
      <td>
        {props.firstName} {props.lastName}
      </td>
      <td>{formatScore(props.score, props.time)}</td>
    </tr>
  );
};
