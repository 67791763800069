import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from "react-router-dom";
import "./sass/app.scss";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ScoreBoard } from "./features/scoreBoard/scoreBoard.page";
import "bootstrap/dist/css/bootstrap.min.css";
import { AddScore } from "./features/addScore/addScore.page";
import { MainLayout } from "./layouts/main.layout";
import { Logout } from "./features/login/logout.page";
import { Login } from "./features/login/login.page";
import { ScoreList } from "./features/scoreList/scoreList.page";
function App() {
  const queryClient = new QueryClient();
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route path="/" element={<ScoreBoard />} />
        <Route path="admin" element={<MainLayout />}>
          <Route path="" element={<Navigate to="/admin/scores/toevoegen" />} />
          <Route path="scores/toevoegen" element={<AddScore />} />
          <Route path="scores/" element={<ScoreList />} />
        </Route>
        <Route path="logout" element={<Logout />}></Route>
        <Route path="login" element={<Login />}></Route>
      </Route>
    )
  );
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </div>
  );
}

export default App;
