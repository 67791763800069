import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { addScore } from "../addScore.query";
import { InputField } from "./InputField.component";
import { SeriesSelect } from "./seriesSelect.components";
import "react-toastify/dist/ReactToastify.css";
import { extractTime } from "../../../utils/formatting.util";

export const ScoreForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [series, setSeries] = useState(-1);
  const [score, setScore] = useState("");
  const [time, setTime] = useState("");
  const [city, setCity] = useState("");
  const [vehicle, setVehicle] = useState("");
  const [validated, setValidated] = useState(false);
  const [timeValidated, setTimeValidated] = useState(false);

  const setTimeValidate = (value: string) => {
    setTime(value);
    if (!timeValidated && !isNaN(extractTime(value))) {
      setTimeValidated(true);
    }
  };
  const { mutate, isLoading } = useMutation({
    mutationFn: addScore,
    onSuccess: (data) => {
      console.log("Success", data);
      toast.success("Score toegevoegd!");
    },
    onError: (error) => {
      console.log("error", error);
      toast.error(
        "Er is iets misgegaan bij het opslaan van de score. Probeer het later opnieuw of neem contact op met de beheerder."
      );
    },
  });

  const isFormInvalid = () => {
    const totalSeconds = extractTime(time);
    console.log(time, time && isNaN(totalSeconds));

    return (
      firstName === "" ||
      lastName === "" ||
      series === -1 ||
      isNaN(parseFloat(score)) ||
      (score === "100" && isNaN(totalSeconds))
    );
  };
  const handleSubmit = () => {
    if (isFormInvalid()) {
      setValidated(true);
      toast.error("De ingevulde gegevens zijn niet correct.");
      return;
    }
    const parsedScore = parseInt(score);
    const parsedTime = extractTime(time);

    mutate({
      data: {
        series_id: series,
        first_name: firstName,
        last_name: lastName,
        score: parsedScore,
        time: isNaN(parsedTime) ? undefined : parsedTime,
        city: city === "" ? undefined : city,
        vehicle: vehicle === "" ? undefined : vehicle,
      },
    });
  };
  console.log("valid", validated);

  return (
    <div>
      <Row>
        <InputField
          validated={validated}
          name={"Voornaam"}
          value={firstName}
          setValue={setFirstName}
          required={true}
          isValid={(value) => value !== ""}
        />
        <InputField
          validated={validated}
          name={"Achternaam"}
          value={lastName}
          setValue={setLastName}
          required={true}
          isValid={(value) => value !== ""}
        />
        <InputField
          validated={validated}
          name={"Afstand"}
          value={score}
          setValue={(value) => {
            if (value !== "100" && time !== "") {
              setTime("");
            }
            setScore(value);
          }}
          required={true}
          isValid={(value) => !isNaN(parseFloat(value))}
        />
        <InputField
          validated={validated}
          required={score === "100"}
          disabled={score !== "100"}
          name={"Time (mm:ss)"}
          placeholder="e.g. 23:45"
          value={time}
          setValue={setTimeValidate}
          isValid={(value) =>
            !(timeValidated || validated) || !isNaN(extractTime(value))
          }
        />

        <InputField
          validated={validated}
          name={"Gemeente"}
          value={city}
          setValue={setCity}
        />
        <InputField
          validated={validated}
          name={"Voertuig"}
          value={vehicle}
          setValue={setVehicle}
        />

        <Col sm={12} md={6}>
          <Form.Label className="required">Reeks</Form.Label>
          <SeriesSelect
            value={series}
            setValue={setSeries}
            validated={validated}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Button variant="success" disabled={isLoading} onClick={handleSubmit}>
            Toevoegen
          </Button>
        </Col>
      </Row>
    </div>
  );
};
