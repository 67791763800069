import { Container } from "react-bootstrap";
import { ScoreForm } from "./components/scoreForm.component";
import { ToastContainer } from "react-toastify";

export const AddScore = () => {
  return (
    <Container className="add-score">
      <ToastContainer autoClose={4000} />
      <h1 className="title">Score toevoegen</h1>
      <ScoreForm />
    </Container>
  );
};
